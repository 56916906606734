import React, { useContext } from "react";
import PropTypes from "prop-types";
import smoothscroll from "smoothscroll";

import { actions, store } from "../context/store";

const messages = {
  coaching: {
    contactMessage: "I'd like to start with a free coaching session!",
    buttonText: "Free Coaching Session",
    interestOneOnOne: true,
    interestGroup: false,
    interestWorkshop: false,
  },
  coachingGetStarted: {
    contactMessage: "I'd like to start with a free coaching session!",
    buttonText: "Get Started",
    interestOneOnOne: true,
    interestGroup: false,
    interestWorkshop: false,
  },
  workshop: {
    contactMessage:
      "I'd like to know when the next live workshop is happening!",
    buttonText: "Attend Next Event",
    interestOneOnOne: false,
    interestGroup: false,
    interestWorkshop: true,
  },
  clearing: {
    contactMessage: "I'd like to know when The Clearing is happening next!",
    buttonText: "Attend Next Event",
    interestOneOnOne: false,
    interestGroup: false,
    interestWorkshop: true,
  },
  wineAndWonder: {
    contactMessage: "I'd like to know when Wine and Wonder is happening next!",
    buttonText: "Attend Next Event",
    interestOneOnOne: false,
    interestGroup: false,
    interestWorkshop: true,
  },
};

const CtaButton = ({ buttonType }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <button
      className="button scrolly"
      onClick={() => {
        smoothscroll(document.getElementById("contact"), 500, () => {
          dispatch({
            type: actions.UPDATE_CONTACT_FORM_MULTIPLE,
            payload: [
              { key: 'contactMessage', value: messages[buttonType].contactMessage },
              { key: 'interestOneOnOne', value: messages[buttonType].interestOneOnOne },
              { key: 'interestGroup', value: messages[buttonType].interestGroup },
              { key: 'interestWorkshop', value: messages[buttonType].interestWorkshop },
            ]
          });
        });
      }}
    >
      {messages[buttonType].buttonText}
    </button>
  );
};

export default CtaButton;

CtaButton.propTypes = {
  buttonType: PropTypes.string,
};
