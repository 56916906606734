import React, { useEffect, useState } from 'react';
import '../assets/scss/main.scss';

import NavBar from '../components/NavBar';
import FooterComponent from './FooterComponent';

const Template = ({ children }) => {
  const [loading, setLoading] = useState('is-loading');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading('');
    }, 100);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, []);

  return (
    <div className={`body ${loading}`}>
      <NavBar />
      {children}
      <FooterComponent />
    </div>
  );
}

export default Template;
