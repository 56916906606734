import axios from 'axios';
import { actions } from '../context/store';

const handleSubmit = (event, contactForm, dispatch) => {
  event.preventDefault();
  const {
    contactName,
    contactEmail,
    contactMessage,
    interestOneOnOne,
    interestGroup,
    interestWorkshop,
    messageSent,
    error,
  } = contactForm;

  if (messageSent || error) {
    return;
  }

  axios
    .post("https://formspree.io/xdopkgqj", {
      name: contactName,
      email: contactEmail,
      message: contactMessage,
      interest_in_one_on_one_coaching: interestOneOnOne,
      interest_in_group_coaching: interestGroup,
      interest_in_live_workshops: interestWorkshop,
    })
    .then(res => {
      dispatch({
        type: actions.UPDATE_CONTACT_FORM_MULTIPLE,
        payload: [
          { key: "contactName", value: "" },
          { key: "contactEmail", value: "" },
          { key: "contactMessage", value: "" },
          { key: "interestOneOnOne", value: false },
          { key: "interestGroup", value: false },
          { key: "interestWorkshop", value: false },
          { key: "messageSent", value: true },
        ],
      });
      console.log("SUCCESS", res);
    })
    .catch(err => {
      console.log("ERROR", err);
      dispatch({
        type: actions.UPDATE_CONTACT_FORM,
        payload: { key: "error", value: true },
      });
    });
};

export default handleSubmit;