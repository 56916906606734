import React, { useEffect, useState } from 'react';

import Logo from './Logo';

const NavBar = () => {
  const [scrollState, setScrollState] = useState('loading');

  function listenForHittingTopOfPage() {
    if (window.pageYOffset === 0) {
      document.removeEventListener('scroll', listenForHittingTopOfPage);
      document.addEventListener('scroll', listenForLeavingTopOfPage);
      setScrollState('unscrolled');
    }
  }

  function listenForLeavingTopOfPage() {
    if (window.pageYOffset !== 0) {
      document.removeEventListener('scroll', listenForLeavingTopOfPage);
      document.addEventListener('scroll', listenForHittingTopOfPage);
      setScrollState('scrolled');
    }
  }

  function setScrollListeners() {
    window.setTimeout(() => {
      if (window.pageYOffset === 0) {
        document.addEventListener('scroll', listenForLeavingTopOfPage);
        setScrollState('unscrolled');
      } else {
        document.addEventListener('scroll', listenForHittingTopOfPage);
      }
    }, 1);
  
    return () => {
      document.removeEventListener('scroll', listenForHittingTopOfPage);
      document.removeEventListener('scroll', listenForLeavingTopOfPage);
    }
  }

  useEffect(setScrollListeners, []);

  return (
    <div
      className={`header bg-primary-color header--${scrollState}`}
      style={styles.headerStyle}
    >
      <div style={styles.contentContainerStyle}>
        <div style={{ cursor: 'pointer' }}>
          <Logo />
        </div>
      </div>
    </div>
  );
}

export default NavBar;

const styles = {
  headerStyle: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
  },
  contentContainerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoWrapperStyle: {
    display: 'flex'
  },
  navStyle: {
    listStyle: 'none',
    display: 'flex',
    marginRight: '10px',
    textDecoration: 'none',
    margin: 0,
    backgroundImage: 'none',
    textShadow: 'none',
    cursor: 'pointer'
  },
  listItemStyle: {
    marginBottom: 0
  },
  navLinkStyle: {},
  navTextStyle: {
    marginLeft: '20px'
  }
};
