import React, { useContext } from "react";

import { actions, store, Consumer } from "../context/store";
import handleSubmit from '../util/handleSubmit';

const Contact = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const updateFormStringVal = (event, dispatch, key) => {
    dispatch({
      type: actions.UPDATE_CONTACT_FORM,
      payload: {
        key,
        value: event.target.value,
      },
    });
  };

  const updateFormBoolVal = (contactForm, dispatch, key) => {
    dispatch({
      type: actions.UPDATE_CONTACT_FORM,
      payload: {
        key,
        value: !contactForm[key],
      },
    });
  };

  return (
    <Consumer>
      {value => {
        const { contactForm } = value.state;

        return (
          <section id="contact" className="main style1">
            <div className="container">
              <header className="major special">
                <h2>Contact</h2>
              </header>
            </div>
            <form onSubmit={(e) => handleSubmit(e, contactForm, dispatch)}>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="grid-wrapper">
                <div className="col-12">
                  <label htmlFor="name">Name</label>
                  <input
                    required
                    name="name"
                    id="name"
                    type="text"
                    value={contactForm.contactName}
                    onChange={e =>
                      updateFormStringVal(e, dispatch, "contactName")
                    }
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    required
                    name="email"
                    id="email"
                    type="email"
                    value={contactForm.contactEmail}
                    onChange={e =>
                      updateFormStringVal(e, dispatch, "contactEmail")
                    }
                  />
                  <label htmlFor="message">Message</label>
                  <textarea
                    required
                    name="message"
                    id="message"
                    value={contactForm.contactMessage}
                    onChange={e =>
                      updateFormStringVal(e, dispatch, "contactMessage")
                    }
                  />
                  <span className="label-style">
                    Coaching I&apos;m interested in (Select all that apply)
                  </span>
                </div>
              </div>
              <div className="grid-wrapper align-left-in-desktop">
                <div className="col-4">
                  <input
                    type="checkbox"
                    name="coaching"
                    id="one-on-one"
                    value="one-on-one"
                    checked={contactForm.interestOneOnOne}
                    onChange={() =>
                      updateFormBoolVal(
                        contactForm,
                        dispatch,
                        "interestOneOnOne"
                      )
                    }
                  />
                  <label htmlFor="one-on-one">One-on-One Coaching</label>
                </div>
                <div className="col-4 align-center-in-desktop">
                  <input
                    type="checkbox"
                    name="coaching"
                    id="group-coaching"
                    value="group-coaching"
                    checked={contactForm.interestGroup}
                    onChange={() =>
                      updateFormBoolVal(contactForm, dispatch, "interestGroup")
                    }
                  />
                  <label htmlFor="group-coaching">Group Coaching</label>
                </div>
                <div className="col-4 align-right-in-desktop">
                  <input
                    type="checkbox"
                    name="coaching"
                    id="live-workshop"
                    value="live-workshop"
                    checked={contactForm.interestWorkshop}
                    onChange={() =>
                      updateFormBoolVal(
                        contactForm,
                        dispatch,
                        "interestWorkshop"
                      )
                    }
                  />
                  <label htmlFor="live-workshop">Live Workshop</label>
                </div>
              </div>
              <div className="grid-wrapper">
                <div className="col-12 align-center">
                  <ul className="actions uniform">
                    <li>
                      {contactForm.messageSent ? (
                        <span>Message Sent!</span>
                      ) : contactForm.error ? (
                        <span>Sorry, there was an error!</span>
                      ) : (
                        <button type="submit" className="button">
                          Send
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </section>
        );
      }}
    </Consumer>
  );
};

export default Contact;
