import React from 'react';
import PropTypes from 'prop-types';
import CtaButton from '../components/CtaButton';

const HeaderComponent = ({ children, buttonType, backgroundImg }) => (
  <section
    id="header"
    style={{
      backgroundImage: `linear-gradient(rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.7)), url(${backgroundImg})`,
    }}
  >
    <div className="position-top-right desktop-only">
      <CtaButton buttonType={buttonType} />
    </div>
    <div className="inner">
      <span className="icon major fa-flask"></span>
      <h1>
        <span style={{ fontSize: "2rem" }}>Experiment with Wonder</span>
        <br style={{ display: "inline" }} />
        {children}
      </h1>
      <ul className="actions">
        <li>
          <CtaButton buttonType={buttonType} />
        </li>
      </ul>
    </div>
  </section>
)

export default HeaderComponent;

HeaderComponent.propTypes = {
  children: PropTypes.element,
  buttonType: PropTypes.string,
  backgroundImg: PropTypes.string
};